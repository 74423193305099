/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useState } from 'react';
import Aux from 'hoc/Aux';

// reactstrap components
import {
  Button,
  FormGroup,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  UncontrolledPopover,
  PopoverBody,
  PopoverHeader,
  Modal,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  UncontrolledCarousel,
} from 'reactstrap';

import classnames from 'classnames';

import { connect } from 'react-redux';
import AuthContext from './../services/AuthContext';

import { Route, Switch, Redirect } from 'react-router-dom';

const Main = ({ mainProps }) => {
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const showLoginForm = () => {
    setIsAuthenticating(true);
  };

  return <div id="outer-container">{mainProps.children}</div>;
};

const mapStateToProps = (state, ownProps) => ({
  ui: state.ui,
  mainProps: ownProps,
});
export default connect(mapStateToProps, {})(Main);
