/* eslint-disable react-hooks/exhaustive-deps */
/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";

import { Route, Switch, Redirect } from "react-router-dom";

// reactstrap components
import { Button, Modal, Alert } from "reactstrap";

import firebase from "firebase";

import "assets/css/nucleo-icons.css";
import "assets/scss/blk-design-system-react.scss?v=1.1.0";
import "assets/demo/demo.css";

import LandingPage from "views/examples/LandingPage.js";
import Main from "views/Main";
import Account from "./examples/Account";

import AuthContext from "./../services/AuthContext";
import { useAuth } from "./../hooks/useAuth";
import "./../assets/css/custom.css";
import Product from "./examples/Product";
import Checkout from "./examples/Checkout";
import History from "./examples/History";
import Products from "./examples/Products";
import { useAlert } from "hooks/useAlert";
import ScrollToTop from "hooks/ScrollToTop";
import Track from "./examples/Track";
import Refund from "./examples/Refund";
import { appConfig } from "services/firebaseApp";
import LoaderGif from "./../assets/img/loaders/logo512.png";

firebase.initializeApp(appConfig);

const App = () => {
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [isLoading, setIsLoading] = React.useState("");
  const [isLoadingCart, setIsLoadingCart] = useState("");
  const [newUser, setNewUser] = useState(false);
  const [showError, setShowError] = useState(true);

  const [error, setError] = useState("");
  const [cartItems, setCartItems] = useState([]);
  const [hasCart, setHasCart] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [loadingUserInfo, setLoadingUserInfo] = useState(false);

  const { pending, isSignedIn, user } = useAuth();
  const { showAlert } = useAlert();

  useEffect(() => {
    if (isSignedIn) {
      setNewUser(false);
    }
  }, [isSignedIn]);

  useEffect(() => {
    if (user !== null) {
      showAlert(
        <span>
          <strong>Welcome</strong> to Pass Shop!
        </span>
      );
      getUserInfo();
      getCart();
    }
  }, [user]);

  useEffect(() => {
    if (showError) {
      setTimeout(() => {
        setShowError(false);
      }, 2000);
    }
  }, [showError]);

  if (pending) {
    return (
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          justifyItems: "center",
        }}
      >
        <img alt="loader" src={LoaderGif} style={{ width: 320 }} />
      </div>
    );
  }

  const googleProvider = new firebase.auth.GoogleAuthProvider();
  var twitterProvider = new firebase.auth.TwitterAuthProvider();

  googleProvider.addScope("https://www.googleapis.com/auth/contacts.readonly");

  const showLoginForm = () => {
    setIsAuthenticating(true);
  };

  const googleSignIn = () => {
    setIsLoading(true);
    firebase
      .auth()
      .signInWithPopup(googleProvider)
      .then((result) => {
        setIsLoading(false);
        setIsAuthenticating(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const twitterSignIn = () => {
    setIsLoading(true);
    firebase
      .auth()
      .signInWithPopup(twitterProvider)
      .then((result) => {
        setIsLoading(false);
        setIsAuthenticating(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const logout = () => {
    firebase
      .auth()
      .signOut()
      .then(function () {
        // Sign-out successful.
      })
      .catch(function (error) {
        // An error happened.
      });
  };

  const addToCart = (product, quantity, size) => {
    setIsLoadingCart(true);

    const data = {
      item: product,
      units: quantity,
      size: size,
    };

    if (hasCart) {
      firebase
        .firestore()
        .collection("cart")
        .doc(user.uid)
        .update({
          items: firebase.firestore.FieldValue.arrayUnion(data),
        })
        .then((snapshot) => {
          setIsLoadingCart(false);
          setCartItems([data, ...cartItems]);
          showAlert("Added!");
        })
        .catch((err) => {
          setIsLoadingCart(false);
          console.log(err);
        });
    } else {
      firebase
        .firestore()
        .collection("cart")
        .doc(user.uid)
        .set({
          items: firebase.firestore.FieldValue.arrayUnion(data),
        })
        .then((snapshot) => {
          setIsLoadingCart(false);
          setCartItems([data]);
          showAlert("Added!");
        })
        .catch((err) => {
          setIsLoadingCart(false);
          console.log(err);
        });
    }
  };

  const removeCart = (item) => {
    setIsLoadingCart(true);

    const currentItems = cartItems.filter((i) => i !== item);

    firebase
      .firestore()
      .collection("cart")
      .doc(user.uid)
      .set({
        items: currentItems,
      })
      .then((snapshot) => {
        setIsLoadingCart(false);
        setCartItems(cartItems.filter((i) => i !== item));
      })
      .catch((err) => {
        setIsLoadingCart(false);
      });
  };

  const getCart = () => {
    setIsLoadingCart(true);
    firebase
      .firestore()
      .collection("cart")
      .doc(user.uid)
      .get()
      .then((snapshot) => {
        setIsLoadingCart(false);
        if (snapshot.data()) {
          setHasCart(true);
          setCartItems(snapshot.data().items);
        }
      })
      .catch((err) => {
        setIsLoadingCart(false);
      });
  };

  const clearCart = () => {
    setIsLoadingCart(true);
    firebase
      .firestore()
      .collection("cart")
      .doc(user.uid)
      .set({
        items: [],
      })
      .then((snapshot) => {
        setIsLoadingCart(false);
        setCartItems([]);
      })
      .catch((err) => {
        setIsLoadingCart(false);
        console.log(err);
      });
  };

  const getUserInfo = () => {
    setLoadingUserInfo(true);
    firebase
      .firestore()
      .collection("public_profiles")
      .doc(user.uid)
      .get()
      .then((snapshot) => {
        setLoadingUserInfo(false);
        if (snapshot.data()) {
          setUserInfo(snapshot.data());
        }
      })
      .catch((err) => {
        setLoadingUserInfo(false);
      });
  };

  const saveUserInfo = (names, phone, email, deliveryInfo, callback) => {
    // setLoadingUserInfo(true);
    // Remove
    callback();
  };

  const updateUserInfo = (names, phone, email, deliveryInfo, callback) => {
    // setLoadingUserInfo(true);
    // Remove
    callback();
  };

  return (
    <AuthContext.Provider
      value={{
        showLoginForm,
        isSignedIn,
        user,
        db: firebase.firestore(),
        storage: firebase.storage(),
        https: firebase.functions(),
        logout,
        cartItems,
        addToCart,
        isLoadingCart,
        userInfo,
        loadingUserInfo,
        saveUserInfo,
        updateUserInfo,
        removeCart,
        clearCart,
      }}
    >
      <>
        {/* {isShowingAlert && alert} */}
        <ScrollToTop />
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => (
              <Main>
                <LandingPage {...props} />
              </Main>
            )}
          />
          <Route
            exact
            path="/product/:id"
            render={(props) => (
              <Main>
                <Product {...props} />
              </Main>
            )}
          />
          <Route
            exact
            path="/products"
            render={(props) => (
              <Main>
                <Products {...props} />
              </Main>
            )}
          />

          <Route
            exact
            path="/refund-and-cancellation"
            render={(props) => (
              <Main>
                <Refund {...props} />
              </Main>
            )}
          />

          {isSignedIn && (
            <>
              <Route
                exact
                path="/history"
                render={(props) => (
                  <Main>
                    <History {...props} />
                  </Main>
                )}
              />
              <Route
                exact
                path="/account"
                render={(props) => (
                  <Main>
                    <Account {...props} />
                  </Main>
                )}
              />
              <Route
                exact
                path="/cart"
                render={(props) => (
                  <Main>
                    <Checkout {...props} />
                  </Main>
                )}
              />
              <Route
                exact
                path="/track/:id"
                render={(props) => (
                  <Main>
                    <Track {...props} />
                  </Main>
                )}
              />
            </>
          )}

          <Redirect to="/" />
        </Switch>
        {/* Start Form Modal */}
        <Modal modalClassName="modal" isOpen={isAuthenticating}>
          <div className="modal-header justify-content-center">
            <button
              className="close"
              onClick={() => setIsAuthenticating(false)}
              disabled={isLoading}
            >
              <i className="tim-icons icon-simple-remove  txt-black" />
            </button>
            <div className="text-muted text-center ml-auto mr-auto">
              <h3 className="mb-0 txt-black">
                {newUser ? "Create account with" : "Sign in with"}
              </h3>
            </div>
          </div>
          <div className="modal-body">
            <div className="btn-wrapper text-center">
              <Alert
                color="danger"
                isOpen={showError}
                toggle={() => setShowError(false)}
              >
                {error}
              </Alert>
              <Button
                className="btn-neutral btn-icon"
                color="default"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  googleSignIn();
                }}
                disabled={isLoading}
              >
                <img alt="..." src={require("assets/img/google.svg")} />
              </Button>
              <Button
                className="btn-neutral btn-icon"
                color="default"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  twitterSignIn();
                }}
                disabled={isLoading}
              >
                <img alt="..." src={require("assets/img/twitter.png")} />
              </Button>
            </div>
          </div>
        </Modal>
        {/* End Form Modal */}
      </>
    </AuthContext.Provider>
  );
};

export default App;
