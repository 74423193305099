import React, { useState, useEffect, useRef, useContext } from "react";

import { Button, Row, FormGroup, Col, Alert } from "reactstrap";

import Aux from "hoc/Aux";
import StudioHeader from "components/Navbars/StudioHeader";
import Footer from "components/Footer/Footer";

import { AvForm } from "availity-reactstrap-validation";
import AvFeedback from "availity-reactstrap-validation/lib/AvFeedback";
import AvGroup from "availity-reactstrap-validation/lib/AvGroup";
import AvInput from "availity-reactstrap-validation/lib/AvInput";
import AuthContext from "./../../services/AuthContext";
import Loader from "./../../components/Custom/Loader";
import Label from "reactstrap/lib/Label";

const Account = ({ history }) => {
  const {
    user,
    db,
    storage,
    userInfo,
    loadingUserInfo,
    saveUserInfo,
    updateUserInfo,
  } = useContext(AuthContext);
  const [names, setNames] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [delivery, setDelivery] = useState("");

  useEffect(() => {
    if (userInfo) {
      setNames(userInfo.names ?? "");
      setPhone(userInfo.phone);
      setEmail(userInfo.email);
      setDelivery(userInfo.deliveryInfo);
    }
  }, [userInfo]);

  const handleSubmit = (event, errors, values) => {
    if (errors.length == 0) {
      if (userInfo) {
        updateUserInfo(names, phone, email, delivery, () => {});
      } else {
        saveUserInfo(names, phone, email, delivery, () => {});
      }
    }
  };

  return (
    <Aux>
      <StudioHeader history={history} />
      {loadingUserInfo && <Loader />}
      {/* <Alert color="success" isOpen={success} toggle={() => setSuccess(false)}>
        Changes saved!
      </Alert> */}
      <div style={{ padding: 96 }}>
        <h3 className="txt-black">Payment and delivery information</h3>
        <AvForm onSubmit={handleSubmit} disabled={loadingUserInfo}>
          <Row>
            <Col md="6" lg="6" sm="12">
              <AvGroup>
                <label>
                  Your name <br />
                  <small>For receipt generation</small>
                </label>
                <AvInput
                  name="names"
                  placeholder="Your name"
                  className="txt-black"
                  type="text"
                  value={names}
                  onChange={(e) => setNames(e.target.value)}
                  required
                />
                <AvFeedback>Forgetting to add your name :(</AvFeedback>
              </AvGroup>
            </Col>
          </Row>

          <Row>
            <Col md="6">
              <AvGroup>
                <label>Contact Phone</label>
                <AvInput
                  name="phone"
                  placeholder="250788991010"
                  pattern="^(2507)([0-9]{8})$"
                  type="phone"
                  className="txt-black"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
                <AvFeedback>Forgetting your phone number :(</AvFeedback>
              </AvGroup>
            </Col>
            <Col md="6">
              <AvGroup>
                <label>Contact Email</label>
                <AvInput
                  placeholder="printspace@email.com"
                  name="email"
                  className="txt-black"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <AvFeedback>Forgetting your email :(</AvFeedback>
              </AvGroup>
            </Col>
            <Col md="12">
              <AvGroup>
                <label>Delivery Instructions</label>
                <AvInput
                  name="delivery"
                  placeholder="Enter delivery information"
                  type="textarea"
                  className="txt-black"
                  value={delivery}
                  onChange={(e) => setDelivery(e.target.value)}
                />
              </AvGroup>
            </Col>
          </Row>
          <Row>
            <Col md="8"></Col>
            <Col md="4">
              <br />
              <FormGroup>
                <Button
                  className="btn-round float-right"
                  color="primary"
                  type="submit"
                  disabled={loadingUserInfo}
                >
                  <span>Save Changes</span>
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </AvForm>
      </div>
      <Footer />
    </Aux>
  );
};

export default Account;
