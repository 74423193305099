import React from 'react';

const Loader = () => {
  return (
    <div className="bar-dis" style={{ display: 'block' }}>
      <div className="bar" />
    </div>
  );
};

export default Loader;

const styles = {
  barDis: {
    display: 'block',
    width: '100%',
    height: 4,
    background: '#272424',
    borderRadius: 0,
    overflow: 'hidden',
  },
  bar: {
    height: '100%',
    background: '#ffffff',
    animation: 'samet 20s ease',
    animationIterationCount: 'infinite',
  },
};
