/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  Badge,
  Spinner,
} from "reactstrap";
import Aux from "hoc/Aux";

import { openCart } from "../../redux/actions/ui";
import { connect } from "react-redux";

import AuthContext from "./../../services/AuthContext";

const StudioHeader = ({
  ui: {
    isOpen: { isOpen },
  },
  mainProps,
  openCart,
  showsSearch,
}) => {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [collapseOut, setCollapseOut] = React.useState(false);
  const [color, setColor] = React.useState("navbar-transparent");
  const [formModal, setFormModal] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [passwordFocus, setPasswordFocus] = React.useState(false);
  const [showSearchField, setShowSearchField] = React.useState(false);
  const {
    isSignedIn,
    showLoginForm,
    logout,
    cartItems,
    isLoadingCart,
  } = useContext(AuthContext);

  const toggleModal = () => {
    setFormModal(!formModal);
  };
  useEffect(() => {
    window.addEventListener("scroll", changeColor);
  }, []);
  useEffect(() => {
    return () => window.removeEventListener("scroll", changeColor);
  }, []);

  const changeColor = () => {
    if (
      document.documentElement.scrollTop > 99 ||
      document.body.scrollTop > 99
    ) {
      setColor("bg-info");
    } else if (
      document.documentElement.scrollTop < 100 ||
      document.body.scrollTop < 100
    ) {
      setColor("navbar-transparent");
    }
  };
  const toggleSearch = () => {
    setShowSearchField(!showSearchField);
  };
  const toggleCollapse = () => {
    document.documentElement.classList.toggle("nav-open");
    setCollapseOpen(!collapseOpen);
  };
  const onCollapseExiting = () => {
    setCollapseOut("collapsing-out");
  };
  const onCollapseExited = () => {
    setCollapseOut("");
  };

  const openInsta = () => {
    window.open("https://www.instagram.com/digital_business.card", "_blank");
  };
  const openWhatsApp = () => {
    window.open("https://wa.me/250791430701?text=Hello%20Pass%2C", "_blank");
  };
  const openTwitter = () => {
    window.open("https://www.twitter.com/@digital_businness.card", "_blank");
  };

  const openPass = () => {
    window.open("https://pass-card.app", "_blank");
  };

  return (
    <Aux>
      <Navbar className="navbar-transparent" color-on-scroll="100" expand="lg">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand to="/" id="navbar-brand" tag={Link}>
              <span style={{ fontSize: 32 }}>Pass Shop</span>
              <br />
              <span
                style={{
                  fontSize: 11,
                  fontStyle: "italic",
                  fontWeight: "normal",
                }}
              >
                Pass Accessories
              </span>
            </NavbarBrand>
            <button
              aria-expanded={collapseOpen}
              className="navbar-toggler navbar-toggler"
              onClick={toggleCollapse}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse
            className={"justify-content-end " + collapseOut}
            navbar
            isOpen={collapseOpen}
            onExiting={onCollapseExiting}
            onExited={onCollapseExited}
          >
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <a href="/" onClick={(e) => e.preventDefault()}>
                    Pass Shop
                  </a>
                </Col>
                <Col className="collapse-close text-right" xs="6">
                  <button
                    aria-expanded={collapseOpen}
                    className="navbar-toggler"
                    onClick={toggleCollapse}
                  >
                    <i className="tim-icons icon-simple-remove" />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav navbar>
              {showsSearch && !showSearchField && (
                <NavItem>
                  <Button
                    className="btn-round btn-icon"
                    color="primary"
                    onClick={toggleSearch}
                  >
                    <i className="tim-icons icon-zoom-split" />
                  </Button>
                </NavItem>
              )}
              {/* <NavItem>
                <NavLink tag={Link} to="/products">
                  <span className="txt-black">Shop merch items</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/business">
                  <span className="txt-black">Items for your business</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/stores">
                  <span className="txt-black">Stores</span>
                </NavLink>
              </NavItem> */}
              <NavItem>
                <Button
                  className="nav-link d-none d-lg-block"
                  style={{ backgroundColor: "#047857" }}
                  onClick={openPass}
                >
                  <i className="tim-icons icon-badge" />
                  Get Pass for your organization
                </Button>
              </NavItem>
              {!isSignedIn && (
                <Aux>
                  <Button
                    className="btn-icon btn-round"
                    color="default"
                    type="button"
                    onClick={() => {
                      showLoginForm();
                    }}
                  >
                    <i className="tim-icons icon-single-02" />
                  </Button>
                </Aux>
              )}
              {isSignedIn && (
                <Aux>
                  <UncontrolledDropdown nav>
                    <DropdownToggle
                      caret
                      color="dark"
                      className="btn-icon btn-round"
                      data-toggle="dropdown"
                      nav
                      onClick={(e) => e.preventDefault()}
                    >
                      <i
                        className="tim-icons icon-single-02"
                        style={{ color: "#000" }}
                      />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-with-icons">
                      <DropdownItem tag={Link} to="/history">
                        <i className="tim-icons icon-bell-55 txt-black" />
                        <span className="txt-black">Orders</span>
                      </DropdownItem>
                      <DropdownItem tag={Link} to="/account">
                        <i className="tim-icons icon-map-big txt-black" />

                        <span className="txt-black">Delivery info</span>
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          logout();
                        }}
                      >
                        <i className="tim-icons icon-button-power" />
                        Logout
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Aux>
              )}
              {isSignedIn && (
                <Aux>
                  <NavItem>
                    <Button
                      className="btn-round btn-icon"
                      color="primary"
                      disabled={isLoadingCart}
                      tag={Link}
                      to="/cart"
                    >
                      <i className="tim-icons icon-cart" />
                      {!isLoadingCart && (
                        <Badge
                          color="default"
                          pill
                          style={{
                            position: "absolute",
                            top: 16,
                            left: 16,
                            height: 16,
                            width: 16,
                            padding: 3,
                          }}
                        >
                          {cartItems.length}
                        </Badge>
                      )}
                      {isLoadingCart && <Spinner color="light" />}
                    </Button>
                  </NavItem>
                </Aux>
              )}
              {/* <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="primary"
                  data-toggle="dropdown"
                  href="#pablo"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <span className="txt-black">Follow Us</span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-with-icons">
                  <DropdownItem
                    onClick={() => {
                      openInsta();
                    }}
                  >
                    <i
                      className="fab fa-instagram black text-black txt-black"
                      style={{ color: "#000" }}
                    />
                    Instagram
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      openWhatsApp();
                    }}
                  >
                    <i className="fab fa-whatsapp" />
                    WhatsApp
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      openTwitter();
                    }}
                  >
                    Twitter
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> */}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      {showSearchField && (
        <Container style={{ maxWidth: 560 }}>
          <Row className="row-grid justify-content-between -items-center text-left">
            <Col lg="10" md="10">
              <Input
                type="text"
                name="search"
                id="searchBox"
                placeholder="Search merch or store"
              />
            </Col>
            <Col lg="2" md="2">
              <Button
                className="btn-round btn-icon"
                color="primary"
                onClick={toggleSearch}
              >
                <i className="tim-icons icon-zoom-split" />
              </Button>
            </Col>
          </Row>
        </Container>
      )}
    </Aux>
  );
};

const mapStateToProps = (state, ownProps) => ({
  ui: state.ui,
  mainProps: ownProps,
});
export default connect(mapStateToProps, { openCart })(StudioHeader);
