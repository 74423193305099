import React, { useState, useEffect, useRef, useContext } from "react";
import classnames from "classnames";
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  Popover,
  PopoverHeader,
  PopoverBody,
  Table,
  UncontrolledPopover,
} from "reactstrap";

import StudioHeader from "components/Navbars/StudioHeader";
import Footer from "components/Footer/Footer";
import AuthContext from "services/AuthContext";

import { AvForm } from "availity-reactstrap-validation";
import AvFeedback from "availity-reactstrap-validation/lib/AvFeedback";
import AvGroup from "availity-reactstrap-validation/lib/AvGroup";
import AvInput from "availity-reactstrap-validation/lib/AvInput";
import Label from "reactstrap/lib/Label";
import Loader from "components/Custom/Loader";

const formatCurrency = (x) =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

const Product = ({
  history,
  match: {
    params: { id },
  },
}) => {
  const {
    db,
    isSignedIn,
    showLoginForm,
    isLoadingCart,
    addToCart,
  } = useContext(AuthContext);
  const [productInfo, setProductInfo] = useState(null);
  const [productId, setProductId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [units, setUnits] = useState(1);
  const [selectedSize, setSelectedSize] = useState("static");
  useEffect(() => {
    getProductInfo();
  }, [id]);

  const getProductInfo = () => {
    setLoading(true);
    db.collection("accessories")
      .doc(id)
      .get()
      .then((snapshot) => {
        setLoading(false);
        setProductId(snapshot.id);
        let data = snapshot.data();
        setProductInfo(data);
        if (data.type === "sub") {
          setSelectedSize("yearly");
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleSubmit = (event, errors, values) => {
    if (errors.length === 0) {
      addToCart(id, units, selectedSize);
    }
  };

  return (
    <>
      <StudioHeader history={history} />
      {loading && <Loader />}
      <Container style={{ paddingTop: 48, paddingBottom: 48 }}>
        <Row>
          <Col md="6">
            <img
              src={productInfo?.img}
              style={{
                height: 324,
                width: "100%",
                objectFit: "contain",
                backgroundColor: "rgba(0,0,0,0.15)",
                marginBottom: 16,
              }}
              alt=""
            />
          </Col>
          <Col md="6">
            <h6 className="txt-black">{productInfo?.name}</h6>
            {productInfo?.price && productInfo.type !== "sub" && (
              <h5 className="txt-black">
                Starting at{" "}$
                {productInfo ? formatCurrency(productInfo?.price.static) : ".."}{" "}
              </h5>
            )}
            {productInfo?.price && productInfo.type === "sub" && (
              <h5 className="txt-black">
                Starting at{" "}$
                {productInfo
                  ? formatCurrency(productInfo?.price.monthly)
                  : ".."}{" "}
              </h5>
            )}
            <p className="txt-black">{productInfo?.desc}</p>
            {productInfo?.price && productInfo.type !== "sub" && (
              <div>
                <Label style={{ marginTop: 32, color: "black" }}>
                  Select option
                </Label>

                <FormGroup check className="txt-black">
                  <Label check className="txt-black">
                    <Input
                      type="radio"
                      className="txt-black"
                      name="Pass branded"
                      checked={selectedSize === "static"}
                      onChange={(e) => {
                        setSelectedSize("static");
                      }}
                    />

                    <span className="txt-black">
                      Pass branded (${formatCurrency(productInfo?.price.static)}){" "}
                    </span>
                  </Label>
                </FormGroup>
                <FormGroup check className="txt-black">
                  <Label check className="txt-black">
                    <Input
                      type="radio"
                      className="txt-black"
                      name="Custom branded"
                      checked={selectedSize === "custom"}
                      onChange={(e) => {
                        setSelectedSize("custom");
                      }}
                    />

                    <span className="txt-black">
                      Custom branded (
                      ${formatCurrency(productInfo?.price.custom)})
                    </span>
                  </Label>
                </FormGroup>
              </div>
            )}
            {productInfo?.price && productInfo.type === "sub" && (
              <div>
                <Label style={{ marginTop: 32, color: "black" }}>
                  Select option
                </Label>

                <FormGroup check className="txt-black">
                  <Label check className="txt-black">
                    <Input
                      type="radio"
                      className="txt-black"
                      name="Monthly"
                      checked={selectedSize === "monthly"}
                      onChange={(e) => {
                        setSelectedSize("monthly");
                      }}
                    />

                    <span className="txt-black">
                      Monthly (${formatCurrency(productInfo?.price.monthly)} RWF){" "}
                    </span>
                  </Label>
                </FormGroup>
                <FormGroup check className="txt-black">
                  <Label check className="txt-black">
                    <Input
                      type="radio"
                      className="txt-black"
                      name="Yearly"
                      checked={selectedSize === "yearly"}
                      onChange={(e) => {
                        setSelectedSize("yearly");
                      }}
                    />

                    <span className="txt-black">
                      Yearly (${formatCurrency(productInfo?.price.yearly)}) -{" "}
                      <span style={{ fontSize: 12, fontStyle: "italic" }}>
                        Save 17%
                      </span>
                    </span>
                  </Label>
                </FormGroup>
              </div>
            )}

            <Label style={{ marginTop: 32, color: "black" }}>Add to Cart</Label>
            <AvForm onSubmit={handleSubmit} disabled={isLoadingCart}>
              <Row>
                <Col md="6">
                  <AvGroup>
                    <label>Units</label>
                    <AvInput
                      placeholder="Units"
                      name="units"
                      type="number"
                      className="txt-black"
                      min={1}
                      value={units}
                      onChange={(e) => setUnits(e.target.value)}
                      required
                    />
                    <AvFeedback>Forgetting units :(</AvFeedback>
                  </AvGroup>
                </Col>
                <Col md="6">
                  <FormGroup style={{ paddingTop: 22 }}>
                    {isSignedIn && (
                      <Button
                        type="submit"
                        className="btn-round"
                        color="success"
                        disabled={isLoadingCart}
                      >
                        <i className="tim-icons icon-cart" /> Add to Cart
                      </Button>
                    )}
                    {!isSignedIn && (
                      <Button
                        className="btn-round"
                        color="primary"
                        onClick={showLoginForm}
                      >
                        <i className="tim-icons icon-button-power" /> Sign In to
                        Add to Cart
                      </Button>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </AvForm>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Product;
