/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import Visa from "./../../assets/img/payments/visa.svg";
import MasterCard from "./../../assets/img/payments/mastercard.svg";
import Mtn from "./../../assets/img/payments/mtnmoney.png";
import Airtel from "./../../assets/img/payments/airtel.svg";
import Google from "./../../assets/img/stores/google.png";
import TwitterIcon from "components/icons/twitter";
import WhatsAppIcon from "components/icons/whatsapp";
import InstagramIcon from "components/icons/instagram";

class Footer extends React.Component {
  _openInsta = () => {
    window.open("https://www.instagram.com/printspace.rw", "_blank");
  };
  _openWhatsApp = () => {
    window.open(
      "https://wa.me/250791430701?text=Hello%20PrintSpace%2C",
      "_blank"
    );
  };
  _openTwitter = () => {
    window.open("https://www.twitter.com/@printspace_rw", "_blank");
  };

  render() {
    return (
      <footer className="footer" style={{ backgroundColor: "#047857" }}>
        <Container>
          <Row>
            <Col md="4">
              <h3 className="title">
                Pass Shop <br />
                <span style={{ fontSize: 9 }}>by</span>{" "}
                <a
                  href="https://www.fabriq.rw"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span
                    style={{ fontSize: 11, fontWeight: "bold", color: "white" }}
                  >
                    Fabriq
                  </span>
                </a>
              </h3>
              <p>
                +250 791 430 701 <br />
                contact@pass-card.app <br />
                Kigali, Rwanda
              </p>
            </Col>
            <Col md="4">
              <Nav>
                <NavItem>
                  <NavLink href="https://pass-card.app/terms" target="_blank">
                    Terms and Conditions
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="https://pass-card.app/privacy" target="_blank">
                    Privacy Policy
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/refund-and-cancellation" tag={Link}>
                    Refund and Cancellation
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="3">
              <h3 className="title">Follow us:</h3>
              <div className="btn-wrapper profile">
                <Button
                  className="btn-icon btn-neutral btn-round btn-simple btn-primary"
                  onClick={() => this._openInsta()}
                  id="tooltip622135962"
                  target="_blank"
                >
                  <InstagramIcon />
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip622135962">
                  Follow us
                </UncontrolledTooltip>
                <Button
                  className="btn-icon btn-neutral btn-round btn-simple txt-black"
                  color="secondary"
                  onClick={() => this._openWhatsApp()}
                  id="tooltip230450801"
                  target="_blank"
                >
                  <WhatsAppIcon />
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip230450801">
                  Send us a text
                </UncontrolledTooltip>
                <Button
                  className="btn-icon btn-neutral btn-round btn-simple"
                  color="secondary"
                  onClick={() => this._openTwitter()}
                  id="tooltip318450379"
                  target="_blank"
                >
                  <TwitterIcon />
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip318450379">
                  Follow us
                </UncontrolledTooltip>
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 16 }}>
            <Col md="4" style={{ marginTop: 16 }}>
              <div>
                <Typography style={{ color: "white", marginBottom: 8 }}>
                  Supported payment methods:
                </Typography>
              </div>
              <img alt="visa" src={Visa} height={32} />
              <img
                alt="mastercard"
                style={{ marginLeft: 8 }}
                src={MasterCard}
                height={32}
              />
              <img
                alt="mtmMomo"
                style={{ marginLeft: 8 }}
                src={Mtn}
                height={32}
              />
              <img
                alt="airtelMoney"
                style={{ marginLeft: 8 }}
                src={Airtel}
                height={32}
              />
            </Col>
            <Col md="4" style={{ marginTop: 16 }}>
              <div>
                <Typography style={{ color: "white", marginBottom: 8 }}>
                  Get the mobile app:
                </Typography>
              </div>
              <a
                href="https://play.google.com/store/apps/details?id=rw.pass.app.fabriq"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt="google" src={Google} height={32} />
              </a>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}

export default Footer;
