import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Modal,
  FormGroup,
  Alert,
} from "reactstrap";

import StudioHeader from "components/Navbars/StudioHeader";
import Footer from "components/Footer/Footer";
import AuthContext from "services/AuthContext";
import CartItem from "./custom/cartItem";
import Skeleton from "@material-ui/lab/Skeleton";
import { AvForm } from "availity-reactstrap-validation";
import AvFeedback from "availity-reactstrap-validation/lib/AvFeedback";
import AvGroup from "availity-reactstrap-validation/lib/AvGroup";
import AvInput from "availity-reactstrap-validation/lib/AvInput";
import { HoopSpinner } from "react-spinners-kit";
import Loader from "components/Custom/Loader";
import { Link } from "react-router-dom";
import firebase from "firebase";
import WaitingPayment from "./custom/WaitingPayment";

const Checkout = ({ history }) => {
  const {
    db,
    user,
    isLoadingCart,
    cartItems,
    userInfo,
    loadingUserInfo,
    saveUserInfo,
    updateUserInfo,
    removeCart,
    clearCart,
  } = React.useContext(AuthContext);

  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [allPrices, setAllPrices] = useState({});
  const [isAddingInfo, setIsAddingInfo] = useState(false);
  const [ordered, setOrdered] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [names, setNames] = useState("");
  const [delivery, setDelivery] = useState("");

  useEffect(() => {
    if (userInfo !== null) {
      setIsAddingInfo(false);
      setNames(userInfo.names ?? "");
      setPhone(userInfo.phone ?? "");
      setEmail(userInfo.email ?? "");
      setDelivery(userInfo.deliveryInfo ?? "");
    }
  }, [userInfo]);

  useEffect(() => {
    if (userInfo !== null) {
      setIsAddingInfo(false);
    }
    setAllPrices(cartItems.map((e) => ({ [e.item]: 0 })));
  }, [cartItems]);

  useEffect(() => {
    var total = 0;
    for (var i = 0; i < cartItems.length; i++) {
      let item = cartItems[i];
      total = total + parseFloat(item.unitPrice) * parseFloat(item.units);
    }
    setSubTotal(total);
  }, [cartItems.length]);

  const handleSubmit = (event, errors, values) => {
    if (errors.length == 0) {
      if (userInfo !== null) {
        updateUserInfo(names, phone, email, delivery, () => placeOrder());
      } else {
        saveUserInfo(names, phone, email, delivery, () => placeOrder());
      }
    }
  };

  const checkout = () => {
    setIsAddingInfo(true);
  };

  const postOrderItems = async (parentId) => {
    let all = cartItems;
    for (var i = 0; i < all.length; i++) {
      const res = await postOrderItem(parentId, all[i]);
    }
    clearCart();
    setOrdered(true);
    setTimeout(() => {
      setOrdered(false);
    }, 3000);
  };

  const postOrderItem = async (parent, item) => {
    console.log("posting order item", parent, item);
    setLoading(true);
    return await db
      .collection("shopOrderItems")
      .add({
        user: user.uid,
        datePlaced: firebase.firestore.FieldValue.serverTimestamp(),
        parentOrder: parent,
        units: item.units,
        size: item.size,
        item: item.item,
        stage: "pending",
      })
      .then((snapshot) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const placeOrder = () => {
    setTotal(subTotal + 1.2);
    setLoading(true);
    db.collection("shopOrders")
      .add({
        user: user.uid,
        datePlaced: firebase.firestore.FieldValue.serverTimestamp(),
        stage: "pending",
        total: subTotal + 1.2,
      })
      .then((snapshot) => {
        setLoading(false);
        setOrderId(snapshot.id);
        postOrderItems(snapshot.id);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const addPrices = (forItem) => {
    const updatedPrices = allPrices;
    updatedPrices[forItem.id] = { price: forItem.price };
    setAllPrices(updatedPrices);

    let total = Object.values(updatedPrices).reduce(
      (tot, obj) => obj.price + tot,
      0
    );
    setSubTotal(total);
  };

  return (
    <>
      <StudioHeader history={history} />
      {(loadingUserInfo || isLoadingCart || loading) && <Loader />}
      <Container style={{ paddingTop: 48, paddingBottom: 48 }}>
        <Alert color="success" isOpen={ordered}>
          <strong>Yay!</strong> your order has been placed!
        </Alert>
        {isLoadingCart && (
          <Row>
            {[0, 1, 2, 3].map((e) => {
              return (
                <Col md="12">
                  <Card>
                    <Row style={{ padding: 16, alignItems: "center" }}>
                      <Col md="1">
                        <Skeleton
                          animation="wave"
                          variant="circle"
                          width={64}
                          height={64}
                          style={{ backgroundColor: "#ececec" }}
                        />
                      </Col>
                      <Col md="11">
                        <Skeleton
                          animation="wave"
                          style={{ backgroundColor: "#ececec" }}
                        />
                        <Skeleton
                          animation="wave"
                          style={{ backgroundColor: "#ececec" }}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              );
            })}
          </Row>
        )}
        {orderId !== null && !loading && (
          <Row
            style={{
              padding: 96,
            }}
            className="justify-content-between align-items-center"
          >
            {!loading && (
              <WaitingPayment
                order={orderId}
                amount={total}
                email={email}
                phone={phone}
                names={names}
                showTrack={true}
              />
            )}
          </Row>
        )}
        {orderId === null && !isLoadingCart && cartItems.length === 0 && (
          <Row
            style={{
              padding: 96,
            }}
            className="justify-content-between align-items-center"
          >
            <Col md="12" className="d-block text-center">
              <p className="txt-black">Your cart is empty</p>
              <Button
                className="btn-round"
                color="primary"
                style={{ marginTop: 16 }}
                tag={Link}
                to="/products"
              >
                Shop accessories
              </Button>
            </Col>
          </Row>
        )}

        {!isLoadingCart && orderId === null && cartItems.length > 0 && (
          <>
            {cartItems.map((item, index) => {
              return (
                <Row
                  key={`${item.item}${index}${item.units}`}
                  style={{ borderBottomColor: "#f1f1f1", borderBottomWidth: 2 }}
                >
                  <Col md="11">
                    <CartItem
                      item={item}
                      onGetTotal={async (total) => {
                        addPrices(total);
                      }}
                    />
                  </Col>
                  <Col
                    md="1"
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      justifyItems: "center",
                    }}
                  >
                    <Button
                      className="btn-round btn-simple btn-icon"
                      style={{ marginTop: 32 }}
                      color="default"
                      disabled={isLoadingCart || loading || loadingUserInfo}
                      onClick={(e) => {
                        setAllPrices([]);
                        removeCart(item);
                      }}
                    >
                      <i className="tim-icons icon-trash-simple" />
                    </Button>
                  </Col>
                </Row>
              );
            })}
            <Row>
              <Col md="9">
                <h6 style={{ marginTop: 12 }}>
                  <span style={{ color: "grey", marginRight: 8 }}>
                    Subtotal:
                  </span>{" "}
                  <strong className="txt-black">${subTotal}</strong>
                </h6>
                <h6 style={{ marginTop: 12 }}>
                  <span style={{ color: "grey", marginRight: 8 }}>
                    Delivery fee
                  </span>{" "}
                  <strong className="txt-black">$1.2</strong>
                </h6>
                <small>We deliver all around Kigali</small>
                <h3 style={{ marginTop: 12 }}>
                  <span style={{ color: "grey", marginRight: 8 }}>Total</span>{" "}
                  <strong className="txt-black">${subTotal + 1.2}</strong>
                </h3>
              </Col>
              <Col md="3">
                {!isAddingInfo && (
                  <Button
                    className="btn-round"
                    color="success"
                    onClick={() => checkout()}
                    disabled={loadingUserInfo || loading || isLoadingCart}
                  >
                    <i className="tim-icons icon-money-coins" /> Checkout
                  </Button>
                )}
              </Col>
            </Row>
          </>
        )}
      </Container>
      <Footer />

      {/* Start Form Modal */}
      <Modal modalClassName="modal" size="lg" isOpen={isAddingInfo}>
        <div className="modal-header justify-content-center">
          <button
            className="close"
            onClick={() => setIsAddingInfo(false)}
            disabled={loadingUserInfo}
          >
            <i className="tim-icons icon-simple-remove  txt-black" />
          </button>
          <div className="text-muted text-center ml-auto mr-auto">
            <h3 className="mb-0 txt-black">Payment and delivery information</h3>
          </div>
        </div>
        <div className="modal-body">
          <div className="text-center text-muted mb-4 mt-3">
            <small>
              We use this information for handling your orders and for
              contacting you for delivery
            </small>
          </div>

          <AvForm onSubmit={handleSubmit} disabled={loadingUserInfo}>
            <Row>
              <Col md="6">
                <AvGroup>
                  <label>
                    Your name <br />
                    <small>For receipt generation</small>
                  </label>
                  <AvInput
                    placeholder="Your names"
                    name="names"
                    type="text"
                    value={names}
                    onChange={(e) => setNames(e.target.value)}
                    required
                    style={{ color: "black" }}
                  />
                  <AvFeedback>Forgetting your name :(</AvFeedback>
                </AvGroup>
              </Col>
              <Col md="6"></Col>
              <Col md="6">
                <AvGroup>
                  <label>Contact Phone</label>
                  <AvInput
                    placeholder="250788991010"
                    pattern="^(2507)([0-9]{8})$"
                    name="phone"
                    type="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                    style={{ color: "black" }}
                  />
                  <AvFeedback>Add phone in format 250789765432</AvFeedback>
                </AvGroup>
              </Col>
              <Col md="6">
                <AvGroup>
                  <label>Contact Email</label>
                  <AvInput
                    placeholder="printspace@email.com"
                    name="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    style={{ color: "black" }}
                  />
                  <AvFeedback>Forgetting your email :(</AvFeedback>
                </AvGroup>
              </Col>
              <Col md="12">
                <AvGroup>
                  <label>Delivery information</label>
                  <AvInput
                    placeholder="Enter delivery information"
                    name="delivery"
                    type="textarea"
                    value={delivery}
                    onChange={(e) => setDelivery(e.target.value)}
                    style={{ color: "black" }}
                  />
                </AvGroup>
              </Col>
            </Row>
            <div className="text-center">
              <FormGroup>
                <Button
                  className="my-4"
                  color="success"
                  type="submit"
                  disabled={loadingUserInfo}
                >
                  {false && <HoopSpinner size={16} color="white" />}
                  {true && (
                    <span>
                      Looks good, Checkout{" "}
                      <i className="tim-icons icon-double-right" />
                    </span>
                  )}
                </Button>
              </FormGroup>
            </div>
          </AvForm>
        </div>
      </Modal>
      {/* End Form Modal */}
    </>
  );
};

export default Checkout;
