import React, { useState, useEffect } from "react";

import {
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
} from "reactstrap";

import AuthContext from "services/AuthContext";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link } from "react-router-dom";

const formatCurrency = (x) =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

const Products = ({ history }) => {
  const { db } = React.useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = () => {
    setLoading(true);
    let query = db.collection("accessories").where("status", "==", "active");

    query
      .get()
      .then((snapshot) => {
        setLoading(false);
        setProducts(snapshot.docs);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const productsList = products.map((productItem, index) => {
    const product = productItem.data();
    return (
      <Col
        className="mt-3 mt-sm-0"
        md="6"
        key={productItem.id}
        tag={Link}
        to={`/product/${productItem.id}`}
      >
        <Card>
          <CardImg
            top
            width="100%"
            src={product.img}
            style={{ maxHeight: 192, objectFit: "contain" }}
          />
          <CardBody>
            <CardTitle
              tag="h5"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight: "bold",
              }}
            >
              {product.name}
            </CardTitle>
            <CardSubtitle
              tag="h6"
              className="mb-2 text-muted"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              Starting from $
              {formatCurrency(
                product.type === "sub"
                  ? product.price.monthly
                  : product.price.static
              )}{" "}
            </CardSubtitle>
            <CardText
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                color: "#000",
              }}
            >
              {product.desc.trim() !== "" ? product.desc : "..."}
            </CardText>
          </CardBody>
        </Card>
      </Col>
    );
  });

  const noResults = (
    <Row style={{ padding: 32 }}>
      <Col>
        <p style={{ textAlign: "center", color: "#000" }}>
          Unable to show items, please try again later.
        </p>
      </Col>
    </Row>
  );

  return (
    <>
      <Row>
        <Col md="12" lg="12">
          <Row>
            <Col md="12">
              <p
                style={{
                  fontSize: 14,
                  color: "black",
                  marginTop: 16,
                  marginBottom: 16,
                }}
              >
                Purchase accessories to accompany your Pass profile. They can be
                kept in a wallet or attached to a phone or a keychain. They are
                made of a durable plastic and are water resistant. The
                accessories are either NFC enabled, or QR code enabled, or both,
                and are customizable with your branding.
              </p>
            </Col>
            {loading &&
              [0, 1, 2, 3].map((e) => {
                return (
                  <Col md="6">
                    <Card>
                      <div style={{ padding: 16 }}>
                        <Skeleton
                          animation="wave"
                          height={244}
                          style={{ backgroundColor: "#ececec" }}
                        />
                        <Skeleton
                          animation="wave"
                          style={{ backgroundColor: "#ececec" }}
                        />
                        <Skeleton
                          animation="wave"
                          style={{ backgroundColor: "#ececec" }}
                        />
                      </div>
                    </Card>
                  </Col>
                );
              })}
            {!loading && productsList}
          </Row>
          {!loading && products.length === 0 && noResults}
        </Col>
      </Row>
    </>
  );
};

export default Products;
