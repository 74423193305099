import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Button, Card } from "reactstrap";

import StudioHeader from "components/Navbars/StudioHeader";
import Footer from "components/Footer/Footer";
import Loader from "components/Custom/Loader";
import AuthContext from "services/AuthContext";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link } from "react-router-dom";

const History = ({ history }) => {
  const [loading, setLoading] = React.useState(false);
  const [orders, setOrders] = useState([]);
  const { user, db } = useContext(AuthContext);

  useEffect(() => {
    getOrders();
  }, []);

  const getOrders = () => {
    setLoading(true);
    console.log(user.uid);
    db.collection("shopOrders")
      .where("user", "==", user.uid)
      .orderBy("datePlaced", "desc")
      .get()
      .then((snapshot) => {
        setLoading(false);
        setOrders(snapshot.docs);
        console.log(snapshot.docs);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  return (
    <>
      <StudioHeader history={history} />
      {loading && <Loader />}
      <Container style={{ paddingTop: 48, paddingBottom: 48 }}>
        {!loading && orders.length === 0 && (
          <Row
            style={{
              padding: 96,
            }}
            className="justify-content-between align-items-center"
          >
            <Col md="12" className="d-block text-center">
              <p className=" txt-black">You have not placed any order yet</p>
              <Button
                className="btn-round"
                color="primary"
                style={{ marginTop: 16 }}
                tag={Link}
                to="/"
              >
                Shop accessories
              </Button>
            </Col>
          </Row>
        )}
        <Row>
          {loading &&
            [0, 1, 2, 4].map((e) => {
              return (
                <Col md="12">
                  <Card>
                    <Row style={{ padding: 16, alignItems: "center" }}>
                      <Col md="4">
                        <Skeleton
                          animation="wave"
                          style={{ backgroundColor: "#ececec" }}
                        />
                      </Col>
                      <Col md="4">
                        <Skeleton
                          animation="wave"
                          style={{ backgroundColor: "#ececec" }}
                        />
                      </Col>
                      <Col md="4">
                        <Skeleton
                          animation="wave"
                          style={{ backgroundColor: "#ececec" }}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              );
            })}
        </Row>
        {orders.length > 0 && (
          <>
            <h3 className="txt-black">My orders</h3>
            {orders.map((order) => {
              return (
                <Col md="12">
                  <Card>
                    <Row style={{ padding: 16, alignItems: "center" }}>
                      <Col md="4">
                        <p className="txt-black">
                          <spa className="txt-black">
                            {order.data().datePlaced.toDate().toLocaleString()}
                          </spa>
                        </p>
                        <p className="txt-black">
                          <small className="txt-black">Total</small>{" "}
                          <span>
                            <h3 className="txt-black">
                              <strong className="txt-black">
                                {order.data().total} RWF
                              </strong>
                            </h3>
                          </span>
                        </p>
                      </Col>
                      <Col md="4">
                        <p className="txt-black">
                          <span className="txt-black">
                            {order.data().stage.toUpperCase()}
                          </span>
                        </p>
                      </Col>
                      <Col md="4">
                        <Button
                          className="btn-round float-right"
                          color="default"
                          tag={Link}
                          to={`/track/${order.id}`}
                        >
                          Track order
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              );
            })}
          </>
        )}
      </Container>
      <Footer />
    </>
  );
};

export default History;
