import React, { useState, useEffect, useRef, useContext } from "react";

import {
  Button,
  Row,
  FormGroup,
  Col,
  Alert,
  Modal,
  Container,
} from "reactstrap";

import Aux from "hoc/Aux";
import StudioHeader from "components/Navbars/StudioHeader";
import Footer from "components/Footer/Footer";

import { AvForm } from "availity-reactstrap-validation";
import AvFeedback from "availity-reactstrap-validation/lib/AvFeedback";
import AvGroup from "availity-reactstrap-validation/lib/AvGroup";
import AvInput from "availity-reactstrap-validation/lib/AvInput";
import AuthContext from "./../../services/AuthContext";
import Loader from "./../../components/Custom/Loader";
import Label from "reactstrap/lib/Label";
import OrderItem from "./custom/OrderItem";
import WaitingPayment from "./custom/WaitingPayment";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@material-ui/lab";
import Moment from "react-moment";
import { firestore } from "firebase";
import { HoopSpinner } from "react-spinners-kit";
import { Typography } from "antd";
// import Timeline from '@mui/lab/Timeline';
// import TimelineItem from '@mui/lab/TimelineItem';
// import TimelineSeparator from '@mui/lab/TimelineSeparator';
// import TimelineConnector from '@mui/lab/TimelineConnector';
// import TimelineContent from '@mui/lab/TimelineContent';
// import TimelineDot from '@mui/lab/TimelineDot';
// import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

Date.prototype.addHours = function (h) {
  this.setHours(this.getHours() + h);
  return this;
};

Date.prototype.addMinutes = function (m) {
  this.setMilliseconds(this.getMilliseconds() + m * 60000);
  return this;
};

Date.prototype.addDays = function (d) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + d);
  return date;
};

const Track = ({
  history,
  match: {
    params: { id },
  },
}) => {
  const {
    user,
    db,
    storage,
    userInfo,
    loadingUserInfo,
    saveUserInfo,
    updateUserInfo,
  } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [orderItems, setOrderItems] = useState([]);
  const [orderData, setOrderData] = useState({});
  const [timeline, setTimeline] = useState([]);
  const [estimated, setEstimated] = useState(null);
  const [isAddingInfo, setIsAddingInfo] = useState(false);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [names, setNames] = useState("");
  const [delivery, setDelivery] = useState("");

  useEffect(() => {
    if (user !== null) {
      getOrder();
    }
  }, [user]);

  useEffect(() => {
    if (userInfo !== null) {
      setIsAddingInfo(false);
      setNames(userInfo.names ?? "");
      setPhone(userInfo.phone ?? "");
      setEmail(userInfo.email ?? "");
      setDelivery(userInfo.deliveryInfo ?? "");
    }
  }, [userInfo]);

  const getOrder = () => {
    setLoading(true);
    db.collection("shopOrders")
      .doc(id)
      .get()
      .then((snapshot) => {
        setLoading(false);
        setOrderData(snapshot.data());
        setTimelineData(snapshot.data());
        getEstimatedDelivery(snapshot.data());
        getItems();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getItems = () => {
    setLoading(true);
    db.collection("shopOrderItems")
      .where("parentOrder", "==", id)
      .get()
      .then((snapshot) => {
        setLoading(false);
        setOrderItems(snapshot.docs);
        console.log(snapshot.docs);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const setTimelineData = (data) => {
    var item = { title: "Placed", date: data.datePlaced };
    var current = timeline;
    current.push(item);
    if (data.paymentProcessed) {
      item = { title: "Payment completed", date: data.paymentProcessed };
      current = timeline;
      current.push(item);
      item = { title: "Printing Started", date: data.paymentProcessed };
      current = timeline;
      current.push(item);
    }
    if (data.shipped) {
      item = { title: "Shipped", date: data.shipped };
      current = timeline;
      current.push(item);
    }
    if (data.delivered) {
      item = { title: "Delivered", date: data.delivered };
      current = timeline;
      current.push(item);
    }
    if (data.paymentFailed) {
      let date = firestore.Timestamp.fromDate(
        data.datePlaced.toDate().addMinutes(8)
      );
      item = { title: "Payment Failed", date: date };
      current = timeline;
      current.push(item);
    }
    setTimeline(current);
  };

  const getEstimatedDelivery = (data) => {
    if (data.shipped) {
      setEstimated(data.shipped.toDate().addHours(2));
    } else if (data.paymentProcessed) {
      setEstimated(data.paymentProcessed.toDate().addDays(2));
    } else if (data.datePlaced) {
      setEstimated(data.datePlaced.toDate().addDays(2));
    }
  };

  const handleSubmit = (event, errors, values) => {
    if (errors.length == 0) {
      if (userInfo !== null) {
        updateUserInfo(names, phone, email, delivery, () => {});
      } else {
        saveUserInfo(names, phone, email, delivery, () => {});
      }
    }
  };

  const items = orderItems.map((item) => {
    let data = item.data();
    return (
      <Col md="3">
        <OrderItem
          id={data.item}
          units={data.units}
          key={item.id}
          stage={data.stage}
        />
      </Col>
    );
  });

  const getAllowPay = () => {
    return (
      userInfo !== null &&
      userInfo.hasOwnProperty("email") &&
      userInfo.hasOwnProperty("phone") &&
      userInfo.hasOwnProperty("names")
    );
  };

  const timelineOptions = timeline.map((item) => {
    return (
      <TimelineItem>
        <TimelineOppositeContent color="text.secondary">
          <Moment
            date={item.date.toDate()}
            format="MMM Do YY, h:mm:ss a"
            className="txt-black"
          />
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent className="txt-black">{item.title}</TimelineContent>
      </TimelineItem>
    );
  });

  return (
    <Aux>
      <StudioHeader history={history} />
      {loading && <Loader />}
      <div style={{ padding: 96 }}>
        <Row className="justify-content-center align-items-center">
          <Col>
            <h3 className="txt-black">
              Order: <small>{id}</small>
            </h3>
          </Col>
          <Col></Col>
        </Row>
        {orderData.stage === "pending" &&
          orderData.paymentFailed === undefined && (
            <div style={{ paddingTop: 48, paddingBottom: 48 }}>
              {!loading && getAllowPay() && (
                <WaitingPayment
                  order={id}
                  amount={orderData.total}
                  email={userInfo.email}
                  phone={userInfo.phone}
                  names={userInfo.names}
                  showTrack={false}
                />
              )}

              {!getAllowPay() && (
                <Col md="12" className="d-block text-center">
                  <small className="txt-black">
                    Your order is on its way to the factory, and is just
                    awaiting for your payment approval
                  </small>
                  <h4 className="txt-black">
                    Please update your payment information in order to proceed
                  </h4>
                  <Button
                    className="btn-round"
                    color="primary"
                    style={{ marginTop: 16 }}
                    onClick={() => setIsAddingInfo(true)}
                    disabled={loading}
                  >
                    Add payment Info
                  </Button>
                </Col>
              )}
            </div>
          )}
        <Row>
          <Col md="7" style={{ borderRight: "1px dashed #333" }}>
            <Row>
              {items}
              <Col md="12" className="txt-black">
                Total{" "}
                <h3 className="txt-black">
                  {orderData.total} <small>Rwf</small>
                </h3>
              </Col>
            </Row>
          </Col>
          <Col md="5">
            <React.Fragment>
              <Timeline position="alternate">{timelineOptions}</Timeline>
              {estimated !== null && orderData.paymentFailed === undefined && (
                <center>
                  <h4 className="txt-black">
                    Estimated Delivery:{" "}
                    {<Moment date={estimated} format="MMM Do, h:mm a" />}
                  </h4>
                </center>
              )}
              <Container
                style={{
                  display: "flex",
                  justifyItems: "flex-end",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                  marginTop: 32,
                }}
              >
                <Typography style={{ fontSize: 11, fontStyle: "italic" }}>
                  Tracked by
                </Typography>

                <Typography
                  style={{ fontSize: 11, fontWeight: "bold", marginLeft: 4 }}
                >
                  enRoute
                </Typography>
              </Container>
            </React.Fragment>
          </Col>
        </Row>
      </div>
      <Footer />

      {/* Start Form Modal */}
      <Modal modalClassName="modal-black" size="lg" isOpen={isAddingInfo}>
        <div className="modal-header justify-content-center">
          <button
            className="close"
            onClick={() => setIsAddingInfo(false)}
            disabled={loadingUserInfo}
          >
            <i className="tim-icons icon-simple-remove txt-black" />
          </button>
          <div className="text-muted text-center ml-auto mr-auto">
            <h3 className="mb-0 txt-black">Payment and delivery information</h3>
          </div>
        </div>
        <div className="modal-body">
          <div className="text-center text-muted mb-4 mt-3">
            <small className="txt-black">
              We use this information for handling your orders and for
              contacting you for delivery
            </small>
          </div>

          <AvForm onSubmit={handleSubmit} disabled={loadingUserInfo}>
            <Row>
              <Col md="6">
                <AvGroup>
                  <label>
                    Your name <br />
                    <small>For receipt generation</small>
                  </label>
                  <AvInput
                    placeholder="Your names"
                    name="names"
                    type="text"
                    value={names}
                    onChange={(e) => setNames(e.target.value)}
                    required
                  />
                  <AvFeedback>Forgetting your name :(</AvFeedback>
                </AvGroup>
              </Col>
              <Col md="6"></Col>
              <Col md="6">
                <AvGroup>
                  <label>Contact Phone</label>
                  <AvInput
                    placeholder="250788991010"
                    pattern="^(2507)([0-9]{8})$"
                    name="phone"
                    type="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  />
                  <AvFeedback>Forgetting your phone :(</AvFeedback>
                </AvGroup>
              </Col>
              <Col md="6">
                <AvGroup>
                  <label>Contact Email</label>
                  <AvInput
                    placeholder="printspace@email.com"
                    name="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <AvFeedback>Forgetting your email :(</AvFeedback>
                </AvGroup>
              </Col>
              <Col md="12">
                <AvGroup>
                  <label>Delivery information</label>
                  <AvInput
                    placeholder="Enter delivery information"
                    name="delivery"
                    type="textarea"
                    value={delivery}
                    onChange={(e) => setDelivery(e.target.value)}
                  />
                </AvGroup>
              </Col>
            </Row>
            <div className="text-center">
              <FormGroup>
                <Button
                  className="my-4"
                  color="success"
                  type="submit"
                  disabled={loadingUserInfo}
                >
                  {false && <HoopSpinner size={16} color="white" />}
                  {true && (
                    <span>
                      Looks good, Checkout{" "}
                      <i className="tim-icons icon-double-right" />
                    </span>
                  )}
                </Button>
              </FormGroup>
            </div>
          </AvForm>
        </div>
      </Modal>
      {/* End Form Modal */}
    </Aux>
  );
};

export default Track;
