import { actionTypes } from '../../action-types';

export default (state, { type }) => {
	switch (type) {
		case actionTypes.ui.OPEN_CART_SIDEBAR:
			return {
				...state,
				isOpen: { isOpen: true }
            };
        case actionTypes.ui.CLOSE_CART_SIDEBAR:
			return {
				...state,
				isOpen: { isOpen: false }
			};
		default:
			return state;
	}
};