import React from "react";

import { Container, Row, Col } from "reactstrap";

import Footer from "components/Footer/Footer.js";

import Aux from "hoc/Aux";
import StudioHeader from "components/Navbars/StudioHeader";

class Refund extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Aux>
        <StudioHeader history={this.props.history} />
        <div className="section section-download" id="download-section">
          <Container>
            <Row className="justify-content-md-center">
              <Col lg="12" md="12">
                <h2 className="title txt-black">
                  Our Refund and Cancellation policy
                </h2>
                <section class="" style={{ marginTop: 40 }}>
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <small className="txt-black">
                          Last Revised: <strong>September 30, 2023</strong>
                        </small>
                        <p className="txt-black">&nbsp;</p>
                        <div class="textSection">
                          <h5 className="txt-black">REFUND AND RETURNS</h5>
                          <p className="txt-black">
                            We strive to make sure you love your order and if
                            for any reason you are not satisfied, we always want
                            to make it right. Please contact us on email at
                            contact@pass-card.app with your claim. Please make
                            sure the item has not been used for a guaranteed
                            full refund. We will arrange return pickup, at your
                            own expense, and move to processing your refund
                            accordingly. Refunds are processed in 3-7 business
                            days after claim has been audited and confirmed.
                          </p>
                          <br />
                          <p className="txt-black">
                            For any quality issues, please make sure to provide
                            clear images of the product(s) in a well lit
                            environment. This will help us identify the claim
                            and thus provide a speedy reslution.
                          </p>

                          <br />

                          <h5 className="txt-black">CANCELLATION</h5>
                          <p className="txt-black">
                            Our services are designed with promptiness in mind.
                            For this reason, we want to process your orders as
                            soon as they come in. Processing your order can
                            sometimes start as soon as payment is processed. You
                            are eligible for cancelling your order if the order
                            status has not moved to 'PRINTING'. Once your order
                            has moved to 'PRINTING', you cannot file a
                            return/refund . Request refunds or cancellation by
                            contacting us on email at contact@pass-card.app.
                            Valid cancellation refunds are processed in 3-7
                            business days after cancellation request has been
                            submitted.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </Col>
            </Row>
          </Container>
        </div>

        <Footer />
      </Aux>
    );
  }
}

export default Refund;
