import React, { useState, useEffect } from "react";
import { Alert } from "reactstrap";

export function useAlert() {
  const [message, setMessage] = useState("");
  const [isShowingAlert, setIsShowingAlert] = useState(false);

  const showAlert = (message) => {
    setMessage(message);
    setIsShowingAlert(true);
    setTimeout(() => {
      setIsShowingAlert(false);
      setMessage(message);
    }, 3000);
  };

  const alert = <Alert color="success">{message}</Alert>;

  return { showAlert, alert, isShowingAlert };
}
