import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";
import AuthContext from "services/AuthContext";

const CartItem = ({ item, onGetTotal }) => {
  const { db, user } = React.useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [productInfo, setProductInfo] = useState(null);

  useEffect(() => {
    getProductInfo(item.item);
  }, [item]);

  const getProductInfo = (id) => {
    db.collection("accessories")
      .doc(id)
      .get()
      .then((snapshot) => {
        setLoading(false);
        setProductInfo(snapshot.data());
        onGetTotal({
          id: item.item,
          price: snapshot.data().price[item.size] * item.units,
        });
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <>
      <Card>
        <CardBody>
          <Row style={{ alignItems: "center" }}>
            <Col md="2">
              <img
                src={productInfo?.img}
                style={{
                  height: 64,
                  width: 64,
                  borderRadius: 32,
                  objectFit: "contain",
                  backgroundColor: "rgba(255,255,255,0.15)",
                }}
              />
            </Col>
            <Col md="10">
              <CardTitle style={{ fontWeight: "500" }}>
                {productInfo?.name} (<small>{item.units}</small>)
              </CardTitle>

              <CardSubtitle>
                ${productInfo ? productInfo.price[item.size] * item.units : ""}
              </CardSubtitle>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default CartItem;
