import React, { useState, useContext } from "react";
import { Col, Button } from "reactstrap";

import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import AuthContext from "services/AuthContext";
import firebase from "firebase";
import { Link } from "react-router-dom";
import { ImpulseSpinner } from "react-spinners-kit";

const WaitingPayment = ({
  order,
  amount,
  phone,
  email,
  names,
  showTrack,
  ready,
}) => {
  const { db } = useContext(AuthContext);

  const [paid, setPaid] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const handleFlutterPayment = useFlutterwave({
    public_key: "FLWPUBK-14953b76b3de121059b07926b54901be-X",
    tx_ref: order,
    amount: amount,
    currency: "USD",
    payment_options: "mobilemoneyrwanda,card",
    customer: {
      email: email,
      phonenumber: phone,
      name: names,
    },
    customizations: {
      title: "Checkout",
      description: "Payment for items in cart",
      logo:
        "https://firebasestorage.googleapis.com/v0/b/main-web-f6391.appspot.com/o/own%2Flogo192.png?alt=media&token=f74de7d4-c5d2-4022-b7df-d575a7dad6e3",
    },
  });

  const requestPayment = () => {
    setLoading(true);
    handleFlutterPayment({
      callback: (response) => {
        closePaymentModal();
        setLoading(false);
        updateOrderPayment(
          response.status === "successful",
          response.flw_ref,
          response.transaction_id
        );
      },
      onClose: () => {
        setLoading(false);
      },
    });
  };

  const updateOrderPayment = (success, ref, transId) => {
    if (success) {
      setLoading(true);
      db.collection("shopOrders")
        .doc(order)
        .update({
          transactionId: transId,
          paymentProcessed: firebase.firestore.FieldValue.serverTimestamp(),
          payRef: ref,
          stage: "printing",
        })
        .then((snapshot) => {
          setPaid(true);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      {!paid && (
        <Col md="12" className="d-block text-center">
          <small>
            Your order is on its way to the factory, and is just awaiting for
            your payment approval
          </small>
          <h4 className="txt-black">
            Please authorize payment below using any of the provided options
          </h4>
          <Button
            className="btn-round"
            color="success"
            style={{ marginTop: 16 }}
            onClick={() => requestPayment()}
            disabled={loading}
          >
            {!loading && <span>Pay Now</span>}
            {loading && <ImpulseSpinner frontColor="#FFF" />}
          </Button>
        </Col>
      )}
      {paid && showTrack && (
        <Col md="12" className="d-block text-center">
          <small>Yay! Your order is on its way to the factory!</small>
          <h4>You can track the progress of your order below</h4>
          <Button
            className="btn-round"
            color="default"
            style={{ marginTop: 16 }}
            tag={Link}
            to={`/track/${order}`}
            disabled={loading}
          >
            Track Order
          </Button>
        </Col>
      )}
    </>
  );
};

export default WaitingPayment;
