import React, { useState, useEffect, useRef, useContext } from "react";
import classnames from "classnames";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap";
import AuthContext from "services/AuthContext";

const OrderItem = ({ id, units }) => {
  const { db, user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const [productInfo, setProductInfo] = useState(null);

  useEffect(() => {
      console.log(id);
    getProductInfo();
  }, [id]);

  const getProductInfo = () => {
    setLoading(true);
    db.collection("merch")
      .doc(id)
      .get()
      .then((snapshot) => {
        setLoading(false);
        setProductInfo(snapshot.data());
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <>
      <Card>
          {
              !loading && productInfo !== undefined &&
              <CardBody>
              <img
                src={productInfo?.designUrl}
                style={{
                  objectFit: "contain",
                  backgroundColor: "rgba(255,255,255,0.15)",
                }}
              />
              <CardTitle style={{ fontWeight: "500", paddingTop: 8, textAlign: 'center' }}>
                {productInfo?.title} (<small>{units}</small>)
              </CardTitle>

              <CardSubtitle>
                {/* {productInfo ? productInfo.price * item.units : ""} RWF */}
              </CardSubtitle>
        </CardBody>
          }
          {
              !loading && productInfo === undefined && 
              <CardBody>
              <small>Error displaying</small>
        </CardBody>
              }
      </Card>
    </>
  );
};

export default OrderItem;