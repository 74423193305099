import React from "react";

import { Row, Col, Container } from "reactstrap";

import Footer from "components/Footer/Footer.js";

import Aux from "hoc/Aux";
import StudioHeader from "components/Navbars/StudioHeader";
import Products from "./Products";
class LandingPage extends React.Component {
  componentDidMount() {
    document.body.classList.toggle("landing-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("landing-page");
  }

  render() {
    return (
      <Aux>
        <StudioHeader history={this.props.history} showsSearch={false} />

        <div className="wrapper">
          <Container>
            <Row className="row-grid justify-content-between -items-center text-left align-items-center">
              <Col lg="12" md="12">
                <Row className="align-items-center">
                  <Col lg="12" md="12">
                    <div style={{ padding: 16 }}>
                      <h3 className="txt-black">
                        Shop Pass accessories
                        <br />
                        <span style={{ fontSize: 12 }}>
                          Cards, tags, and more!
                        </span>
                        <br />
                      </h3>
                    </div>
                  </Col>
                  <Col lg="12" md="12">
                    <Products />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
          <Footer />
        </div>
      </Aux>
    );
  }
}

export default LandingPage;
