import { actionTypes } from '../../action-types';

export const openCart = () => (dispatch) => {
	dispatch({
		type: actionTypes.ui.OPEN_CART_SIDEBAR,
	});
};

export const closeCart = () => (dispatch) => {
	dispatch({
		type: actionTypes.ui.CLOSE_CART_SIDEBAR,
	});
};
